/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header__navigation__search {
  display: inline-flex;
  align-items: center;
}
.header__navigation__search__input {
  max-width: 140px;
}
.header__navigation__search__input .ant-input {
  height: 34px;
}
.header__navigation__search__input .ant-input-prefix {
  left: 11px;
  color: #bdbdbd;
  font-size: 13px;
}
.header__navigation__search__input.ant-input-affix-wrapper .ant-input,
.header__navigation__search__input.ant-input-affix-wrapper:hover .ant-input {
  border-color: #d9d9d9;
}
/*! critical:end */
.header__navigation__search.header__navigation__search--open {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
}
.header__navigation__search.header__navigation__search--open::before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.header__navigation__search.header__navigation__search--open .header__navigation__search__content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 74px;
  background: #fff;
}
.header__navigation__search.header__navigation__search--open .header__navigation__search__content .header__navigation__search__input {
  position: absolute;
  top: 20px;
  right: 50%;
  width: 80%;
  max-width: 400px;
  transform: translateX(50%);
}
.header__navigation__search.header__navigation__search--open .header__navigation__search__content .header__navigation__search__input .ant-input-suffix {
  right: -34px;
  height: 100%;
}
.header__navigation__search.header__navigation__search--open .header__navigation__search__content .header__navigation__search__input .ant-input-suffix .header__navigation__search__input__action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 100%;
  color: #bdbdbd;
  font-size: 11px;
  cursor: pointer;
}
.header__navigation__search.header__navigation__search--open .header__navigation__search__content .header__navigation__search__input .ant-input-suffix .header__navigation__search__input__action:hover {
  color: #404040;
}
