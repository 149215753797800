/*! critical:start */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.icon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}
.icon.icon-stack-1x,
.icon.icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.icon.icon-stack-1x {
  line-height: inherit;
}
.icon.icon-stack-2x {
  font-size: 2em;
}
.icon-2x {
  font-size: 2em;
}
.icon-4x {
  font-size: 4em;
}
.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon--disabled {
  color: #9f9f9f;
}
.icon-stack {
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*! critical:end */
