@import "styles/_variables.scss";

.flash-message {
  display: flex;
  align-items: center;
  padding: 12px $space-1;

  &--sticky {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
  }

  &--info {
    background: $flash-message-info-background-color;
    color: $flash-message-info-text-color;
  }

  &--warning {
    background: $flash-message-warning-background-color;
    color: $flash-message-warning-text-color;
  }

  &--error {
    background: $flash-message-error-background-color;
    color: $flash-message-error-text-color;
  }

  &__icon {
    display: block;
    margin-right: $space-1;
    font-size: 24px;
  }

  &__close {
    height: fit-content;
    margin-left: auto;
    padding: $space-0;
    cursor: pointer;
  }

  &__content {
    padding: $space-0 0;
  }
}
