@font-face {
  font-family: "Icons";
  src: url("https://cdn.eventinc.de/_next/static/media/Icons.3344a285397560e7e487.eot?#iefix") format("embedded-opentype"),
url("https://cdn.eventinc.de/_next/static/media/Icons.3344a285397560e7e487.woff") format("woff"),
url("https://cdn.eventinc.de/_next/static/media/Icons.3344a285397560e7e487.ttf") format("truetype"),
url("https://cdn.eventinc.de/_next/static/media/Icons.3344a285397560e7e487.svg#Icons") format("svg");
  font-display: block;
}

.icon {
  line-height: 1;
}

.icon:before {
  font-family: Icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

.icon-back-arrow:before {
  content: "\f101";
}
.icon-birthday_party:before {
  content: "\f102";
}
.icon-burger:before {
  content: "\f103";
}
.icon-calendar-empty:before {
  content: "\f104";
}
.icon-calendar:before {
  content: "\f105";
}
.icon-camera:before {
  content: "\f106";
}
.icon-catering:before {
  content: "\f107";
}
.icon-caution:before {
  content: "\f108";
}
.icon-check-circle-bordered:before {
  content: "\f109";
}
.icon-check-circle-thin:before {
  content: "\f10a";
}
.icon-christmas_party:before {
  content: "\f10b";
}
.icon-close-thin:before {
  content: "\f10c";
}
.icon-close:before {
  content: "\f10d";
}
.icon-company_event:before {
  content: "\f10e";
}
.icon-conference_congress:before {
  content: "\f10f";
}
.icon-dinner:before {
  content: "\f110";
}
.icon-filter:before {
  content: "\f111";
}
.icon-flag-cheap:before {
  content: "\f112";
}
.icon-gramophone:before {
  content: "\f113";
}
.icon-guests:before {
  content: "\f114";
}
.icon-handle:before {
  content: "\f115";
}
.icon-id:before {
  content: "\f116";
}
.icon-info-circle-bordered:before {
  content: "\f117";
}
.icon-info-circle-thin:before {
  content: "\f118";
}
.icon-instant-booking:before {
  content: "\f119";
}
.icon-loading:before {
  content: "\f11a";
}
.icon-locationtype:before {
  content: "\f11b";
}
.icon-logo:before {
  content: "\f11c";
}
.icon-map-marker-bold:before {
  content: "\f11d";
}
.icon-marker-with-logo:before {
  content: "\f11e";
}
.icon-marker:before {
  content: "\f11f";
}
.icon-meeting:before {
  content: "\f120";
}
.icon-menu:before {
  content: "\f121";
}
.icon-mobile-logo:before {
  content: "\f122";
}
.icon-money-bill-wave-solid:before {
  content: "\f123";
}
.icon-money-static:before {
  content: "\f124";
}
.icon-more:before {
  content: "\f125";
}
.icon-occasion:before {
  content: "\f126";
}
.icon-paperclip:before {
  content: "\f127";
}
.icon-people:before {
  content: "\f128";
}
.icon-phone:before {
  content: "\f129";
}
.icon-photo_film:before {
  content: "\f12a";
}
.icon-plus-icon:before {
  content: "\f12b";
}
.icon-pr_marketing:before {
  content: "\f12c";
}
.icon-private_party:before {
  content: "\f12d";
}
.icon-question-circle:before {
  content: "\f12e";
}
.icon-search-bold:before {
  content: "\f12f";
}
.icon-search:before {
  content: "\f130";
}
.icon-seating-chairs_block:before {
  content: "\f131";
}
.icon-seating-chairs_circle:before {
  content: "\f132";
}
.icon-seating-chairs_gala_banquet:before {
  content: "\f133";
}
.icon-seating-chairs_mixed:before {
  content: "\f134";
}
.icon-seating-chairs_parlament:before {
  content: "\f135";
}
.icon-seating-chairs_reception:before {
  content: "\f136";
}
.icon-seating-chairs_rows:before {
  content: "\f137";
}
.icon-seating-chairs_u_shape:before {
  content: "\f138";
}
.icon-short-logo:before {
  content: "\f139";
}
.icon-speech-bubble:before {
  content: "\f13a";
}
.icon-summer_party:before {
  content: "\f13b";
}
.icon-team_building:before {
  content: "\f13c";
}
.icon-user-bold:before {
  content: "\f13d";
}
.icon-user-solid:before {
  content: "\f13e";
}
.icon-user:before {
  content: "\f13f";
}
.icon-usp-rapid-feedback:before {
  content: "\f140";
}
.icon-wedding:before {
  content: "\f141";
}
.icon-angle-down:before {
  content: "\f142";
}
.icon-angle-left:before {
  content: "\f143";
}
.icon-angle-right:before {
  content: "\f144";
}
.icon-angle-up:before {
  content: "\f145";
}
.icon-arrow-left:before {
  content: "\f146";
}
.icon-arrow-right:before {
  content: "\f147";
}
.icon-ban:before {
  content: "\f148";
}
.icon-bolt:before {
  content: "\f149";
}
.icon-bug:before {
  content: "\f14a";
}
.icon-building:before {
  content: "\f14b";
}
.icon-building-o:before {
  content: "\f14c";
}
.icon-calendar-o:before {
  content: "\f14d";
}
.icon-calendar-times-o:before {
  content: "\f14e";
}
.icon-calendar-plus-o:before {
  content: "\f14f";
}
.icon-caret-down:before {
  content: "\f150";
}
.icon-caret-up:before {
  content: "\f151";
}
.icon-check-circle:before {
  content: "\f152";
}
.icon-check:before {
  content: "\f153";
}
.icon-chevron-right:before {
  content: "\f154";
}
.icon-circle-thin:before {
  content: "\f155";
}
.icon-circle:before {
  content: "\f156";
}
.icon-clipboard:before {
  content: "\f157";
}
.icon-clock-o:before {
  content: "\f158";
}
.icon-cloud-upload:before {
  content: "\f159";
}
.icon-comments-o:before {
  content: "\f15a";
}
.icon-comment-o:before {
  content: "\f15b";
}
.icon-compress:before {
  content: "\f15c";
}
.icon-download:before {
  content: "\f15d";
}
.icon-copy:before {
  content: "\f15e";
}
.icon-envelope-o:before {
  content: "\f15f";
}
.icon-envelope:before {
  content: "\f160";
}
.icon-exclamation-triangle:before {
  content: "\f161";
}
.icon-expand:before {
  content: "\f162";
}
.icon-facebook:before {
  content: "\f163";
}
.icon-file-text-o:before {
  content: "\f164";
}
.icon-group:before {
  content: "\f165";
}
.icon-info-circle:before {
  content: "\f166";
}
.icon-lightbulb-o:before {
  content: "\f167";
}
.icon-line-chart:before {
  content: "\f168";
}
.icon-list-ul:before {
  content: "\f169";
}
.icon-map-o:before {
  content: "\f16a";
}
.icon-map-marker:before {
  content: "\f16b";
}
.icon-newspaper-o:before {
  content: "\f16c";
}
.icon-paper-plane:before {
  content: "\f16d";
}
.icon-pause:before {
  content: "\f16e";
}
.icon-pencil-square-o:before {
  content: "\f16f";
}
.icon-pencil:before {
  content: "\f170";
}
.icon-pinterest:before {
  content: "\f171";
}
.icon-play:before {
  content: "\f172";
}
.icon-plus-circle:before {
  content: "\f173";
}
.icon-print:before {
  content: "\f174";
}
.icon-question-circle-o:before {
  content: "\f175";
}
.icon-refresh:before {
  content: "\f176";
}
.icon-share:before {
  content: "\f177";
}
.icon-star:before {
  content: "\f178";
}
.icon-tags:before {
  content: "\f179";
}
.icon-thumbs-up:before {
  content: "\f17a";
}
.icon-times-circle:before {
  content: "\f17b";
}
.icon-trash-o:before {
  content: "\f17c";
}
.icon-twitter-square:before {
  content: "\f17d";
}
.icon-undo:before {
  content: "\f17e";
}
.icon-youtube-square:before {
  content: "\f17f";
}
.icon-youtube-play:before {
  content: "\f180";
}
.icon-eye:before {
  content: "\f181";
}
.icon-eye-slash:before {
  content: "\f182";
}
