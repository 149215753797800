/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu__submenu--open .menu__submenu__list {
  display: block;
}
.menu__submenu__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 16px;
  cursor: pointer;
}
.menu__submenu__list {
  display: none;
  padding: 0;
  transform-origin: top center;
  animation: growDown 250ms ease-in-out forwards;
}
.menu__submenu__list .menu__item {
  padding-left: 30px;
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
