/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.country-links {
  padding: 0;
  list-style-type: none;
}
.country-links li svg {
  /*! critical:start */
  height: 14px;
  /*! critical:end */
  margin: 0 5px -2px 0;
}
